// Snapshot files
const TheSnapshotMain = () => import('../views/TheSnapshotMain')
const TheSnapshotTableTabset = () => import('../views/TheSnapshotTableTabset')
const TheSnapshotApplist = () => import('../views/TheSnapshotAppList')
const TheSnapshotImagelist = () => import('../views/TheSnapshotImageList')
const TheSnapshotAddSnapshot = () => import('../views/TheSnapshotAddSnapshot')
const TheSnapshotFileDiff = () => import('../views/TheSnapshotFileDiff')
const TheSnapshotDistribute = () => import('../views/TheSnapshotDistribute')
const TheSnapshotOverview = () => import('../views/TheSnapshotOverview')
const Steppers = () => import('@/components/Steppers')
const TheSnapshotSideBar = () => import('../components/TheSnapshotSideBar')
const TheSnapshotAddAppFinalHint = () => import('../views/TheSnapshotAddAppFinalHint')
const TheSnapshotDistributions = () => import('../views/TheSnapshotDistributions')
const TheSnapshotAssignments = () => import('../views/TheSnapshotAssignments')
const Assignment = () => import('../views/Assignment')
const VimeoVideos = () => import('../views/VimeoVideos')
const AppStore = () => import('../views/AppStore')
const AppScale = () => import('../views/AppScale')

// Space files
const TheSpaceDelete = () => import('@/modules/space/views/TheSpaceDelete')
const TheSpaceUserManagement = () => import('@/modules/space/views/TheSpaceUserManagement')
const TheSpaceConfiguration = () => import('@/modules/space/views/TheSpaceConfiguration.vue')
// Instance files
const TheInstanceSnapshotList = () => import('@/modules/instance/views/TheInstanceSnapshotList')

export default [
    {
        path: '/org/:oid/space/:sid/instance/:iid/snapshot/:snid',
        name: 'snapshot-main',
        components: {
            default: TheSnapshotMain,
            'permanent-sidebar': TheSnapshotSideBar
        },
        children: [
            {
                path: 'overview',
                name: 'snapshot-overview',
                components: {
                    default: TheSnapshotOverview
                }
            },
            {
                path: 'tables',
                name: 'snapshot-tables',
                components: {
                    default: TheSnapshotTableTabset
                }
            },
            {
                path: 'manage-snapshots/add-snapshot',
                name: 'snapshot-add-snapshot',
                components: {
                    default: TheSnapshotAddSnapshot
                }
            },
            {
                path: `files/:fileArea/:localPath*`,
                name: 'snapshot-files'
            },
            {
                path: `files`,
                redirect: `files/files`
            },
            {
                path: 'file-diff/:fid',
                name: 'snapshot-file-diff',
                components: {
                    default: TheSnapshotFileDiff
                }
            },
            {
                path: 'applications',
                name: 'snapshot-applications',
                components: {
                    default: TheSnapshotApplist
                }
            },
            {
                path: 'images',
                name: 'snapshot-images',
                components: {
                    default: TheSnapshotImagelist
                }
            },
            {
                path: 'distributions',
                name: 'snapshot-distributions',
                components: {
                    default: TheSnapshotDistributions
                }
            },
            {
                path: 'assignments',
                name: 'snapshot-assignments',
                components: {
                    default: TheSnapshotAssignments
                }
            },
            {
                path: 'assignment/:bid',
                name: 'assignment',
                components: {
                    default: Assignment
                },
                children: [
                    {
                        path: `handin/:hid/files/:localPath*`,
                        name: 'handin'
                    },
                    {
                        path: `handback/:hid/files/:localPath*`,
                        name: 'handback'
                    }
                ]
            },
            {
                path: 'vimeo-videos',
                name: 'vimeo-videos',
                component: VimeoVideos
            },
            {
                path: 'application/:aid/scale',
                name: 'application-scale',
                component: AppScale
            },
            {
                path: 'applications/add',
                name: 'snapshot-applications-add',
                components: {
                    default: AppStore,
                    notifications: Steppers
                }
            },
            {
                path: 'applications/appstore',
                name: 'appstore',
                components: {
                    default: AppStore
                }
            },
            {
                path: 'distribute',
                name: 'snapshot-distribute',
                components: {
                    default: TheSnapshotDistribute
                }
            },
            {
                path: 'addFiles',
                name: 'snapshot-add-files-hint',
                components: {
                    default: TheSnapshotAddAppFinalHint,
                    notifications: Steppers
                }
            },
            {
                path: 'manage/space-user-management',
                name: 'space-user-management',
                components: {
                    default: TheSpaceUserManagement
                }
            },
            {
                path: 'manage/space-delete',
                name: 'space-delete',
                components: {
                    default: TheSpaceDelete
                }
            },
            {
                path: 'manage/space-configuration',
                name: 'space-configuration',
                components: {
                    default: TheSpaceConfiguration
                }
            },
            {
                path: 'manage-snapshots/snapshots',
                name: 'instance-snapshots',
                components: {
                    default: TheInstanceSnapshotList
                }
            },
            {
                path: 'application/:aid/:sessionId?',
                name: 'app-open',
                components: {
                    'permanent-sidebar': TheSnapshotSideBar
                }
            }
        ]
    }
]
